/*
 * Copyright (C) 2012-2020  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

export type Race = "aquatic" | "bird" | "fuzzball" | "wisdom" | "robot";

interface Entry {
    race: Race;
    id: number;
    ai?: boolean;
    shuffle_hash?: number;
}

export const avatar_list: Array<Entry> = [
    { race: "aquatic", id: 63 },
    { race: "aquatic", id: 15 },
    { race: "aquatic", id: 21 },
    { race: "aquatic", id: 35 },
    { race: "aquatic", id: 40 },
    { race: "aquatic", id: 4 },
    { race: "aquatic", id: 43 },
    { race: "aquatic", id: 27 },
    { race: "aquatic", id: 20 },
    { race: "aquatic", id: 5 },
    { race: "aquatic", id: 9, ai: true },
    { race: "aquatic", id: 60 },
    { race: "aquatic", id: 62 },
    { race: "aquatic", id: 57 },
    { race: "aquatic", id: 6 },
    { race: "aquatic", id: 17 },
    { race: "aquatic", id: 56 },
    { race: "aquatic", id: 16 },
    { race: "aquatic", id: 29 },
    { race: "aquatic", id: 18 },
    { race: "aquatic", id: 33 },
    { race: "aquatic", id: 54 },
    { race: "aquatic", id: 53 },
    { race: "aquatic", id: 46 },
    { race: "aquatic", id: 12 },
    { race: "aquatic", id: 38 },
    { race: "aquatic", id: 3 },
    { race: "aquatic", id: 24 },
    { race: "aquatic", id: 50 },
    { race: "aquatic", id: 19 },
    { race: "aquatic", id: 30 },
    { race: "aquatic", id: 31 },
    { race: "aquatic", id: 26 },
    { race: "aquatic", id: 52 },
    { race: "aquatic", id: 41 },
    { race: "aquatic", id: 22 },
    { race: "aquatic", id: 14 },
    { race: "aquatic", id: 55 },
    { race: "aquatic", id: 10 },
    { race: "aquatic", id: 51 },
    { race: "aquatic", id: 2 },
    { race: "aquatic", id: 23 },
    { race: "aquatic", id: 64 },
    { race: "aquatic", id: 47 },
    { race: "aquatic", id: 11 },
    { race: "aquatic", id: 25 },
    { race: "aquatic", id: 39 },
    { race: "aquatic", id: 58 },
    { race: "aquatic", id: 45 },
    { race: "aquatic", id: 42 },
    { race: "aquatic", id: 36 },
    { race: "aquatic", id: 61 },
    { race: "aquatic", id: 13 },
    { race: "aquatic", id: 32 },
    { race: "aquatic", id: 44 },
    { race: "aquatic", id: 37 },
    { race: "aquatic", id: 8 },
    { race: "aquatic", id: 7 },
    { race: "aquatic", id: 48 },
    { race: "aquatic", id: 59 },
    { race: "aquatic", id: 49 },
    { race: "aquatic", id: 34 },
    { race: "aquatic", id: 28 },
    { race: "bird", id: 5 },
    { race: "bird", id: 35 },
    { race: "bird", id: 2 },
    { race: "bird", id: 8 },
    { race: "bird", id: 23 },
    { race: "bird", id: 18 },
    { race: "bird", id: 6 },
    { race: "bird", id: 34 },
    { race: "bird", id: 7 },
    { race: "bird", id: 19 },
    { race: "bird", id: 14 },
    { race: "bird", id: 30 },
    { race: "bird", id: 29 },
    { race: "bird", id: 20 },
    { race: "bird", id: 12 },
    { race: "bird", id: 26, ai: true },
    { race: "bird", id: 31 },
    { race: "bird", id: 9 },
    { race: "bird", id: 16 },
    { race: "bird", id: 4 },
    { race: "bird", id: 28 },
    { race: "bird", id: 21 },
    { race: "bird", id: 13 },
    { race: "bird", id: 27 },
    { race: "bird", id: 22 },
    { race: "bird", id: 10 },
    { race: "bird", id: 15 },
    { race: "bird", id: 36 },
    { race: "bird", id: 17 },
    { race: "bird", id: 32 },
    { race: "bird", id: 11 },
    { race: "bird", id: 25 },
    { race: "bird", id: 33 },
    { race: "bird", id: 24 },
    { race: "bird", id: 38 },
    { race: "bird", id: 3 },
    { race: "bird", id: 37 },
    { race: "fuzzball", id: 177 },
    { race: "fuzzball", id: 133 },
    { race: "fuzzball", id: 64 },
    { race: "fuzzball", id: 144 },
    { race: "fuzzball", id: 176 },
    { race: "fuzzball", id: 121 },
    { race: "fuzzball", id: 65 },
    { race: "fuzzball", id: 62 },
    { race: "fuzzball", id: 159 },
    { race: "fuzzball", id: 129 },
    { race: "fuzzball", id: 57 },
    { race: "fuzzball", id: 150 },
    { race: "fuzzball", id: 68 },
    { race: "fuzzball", id: 115 },
    { race: "fuzzball", id: 12 },
    { race: "fuzzball", id: 146 },
    { race: "fuzzball", id: 5 },
    { race: "fuzzball", id: 9 },
    { race: "fuzzball", id: 39 },
    { race: "fuzzball", id: 145 },
    { race: "fuzzball", id: 124 },
    { race: "fuzzball", id: 122 },
    { race: "fuzzball", id: 63 },
    { race: "fuzzball", id: 11 },
    { race: "fuzzball", id: 169 },
    { race: "fuzzball", id: 45 },
    { race: "fuzzball", id: 56 },
    { race: "fuzzball", id: 178 },
    { race: "fuzzball", id: 123 },
    { race: "fuzzball", id: 148 },
    { race: "fuzzball", id: 26 },
    { race: "fuzzball", id: 28 },
    { race: "fuzzball", id: 97 },
    { race: "fuzzball", id: 88 },
    { race: "fuzzball", id: 60 },
    { race: "fuzzball", id: 6 },
    { race: "fuzzball", id: 74 },
    { race: "fuzzball", id: 23 },
    { race: "fuzzball", id: 171 },
    { race: "fuzzball", id: 21 },
    { race: "fuzzball", id: 81 },
    { race: "fuzzball", id: 154 },
    { race: "fuzzball", id: 94 },
    { race: "fuzzball", id: 135 },
    { race: "fuzzball", id: 51 },
    { race: "fuzzball", id: 31 },
    { race: "fuzzball", id: 138 },
    { race: "fuzzball", id: 158 },
    { race: "fuzzball", id: 84 },
    { race: "fuzzball", id: 15 },
    { race: "fuzzball", id: 119 },
    { race: "fuzzball", id: 46 },
    { race: "fuzzball", id: 38 },
    { race: "fuzzball", id: 156 },
    { race: "fuzzball", id: 157 },
    { race: "fuzzball", id: 40 },
    { race: "fuzzball", id: 153 },
    { race: "fuzzball", id: 1 },
    { race: "fuzzball", id: 92 },
    { race: "fuzzball", id: 108 },
    { race: "fuzzball", id: 55 },
    { race: "fuzzball", id: 172 },
    { race: "fuzzball", id: 162 },
    { race: "fuzzball", id: 44 },
    { race: "fuzzball", id: 112 },
    { race: "fuzzball", id: 99 },
    { race: "fuzzball", id: 109 },
    { race: "fuzzball", id: 47 },
    { race: "fuzzball", id: 149 },
    { race: "fuzzball", id: 86 },
    { race: "fuzzball", id: 139 },
    { race: "fuzzball", id: 36 },
    { race: "fuzzball", id: 30 },
    { race: "fuzzball", id: 118 },
    { race: "fuzzball", id: 25 },
    { race: "fuzzball", id: 161 },
    { race: "fuzzball", id: 167 },
    { race: "fuzzball", id: 52 },
    { race: "fuzzball", id: 93 },
    { race: "fuzzball", id: 132 },
    { race: "fuzzball", id: 77 },
    { race: "fuzzball", id: 32 },
    { race: "fuzzball", id: 22 },
    { race: "fuzzball", id: 113 },
    { race: "fuzzball", id: 17 },
    { race: "fuzzball", id: 103 },
    { race: "fuzzball", id: 170 },
    { race: "fuzzball", id: 7 },
    { race: "fuzzball", id: 20 },
    { race: "fuzzball", id: 70 },
    { race: "fuzzball", id: 33 },
    { race: "fuzzball", id: 141 },
    { race: "fuzzball", id: 27 },
    { race: "fuzzball", id: 89 },
    { race: "fuzzball", id: 41 },
    { race: "fuzzball", id: 24 },
    { race: "fuzzball", id: 152 },
    { race: "fuzzball", id: 19 },
    { race: "fuzzball", id: 4 },
    { race: "fuzzball", id: 85 },
    { race: "fuzzball", id: 110 },
    { race: "fuzzball", id: 37 },
    { race: "fuzzball", id: 173 },
    { race: "fuzzball", id: 80 },
    { race: "fuzzball", id: 29 },
    { race: "fuzzball", id: 131 },
    { race: "fuzzball", id: 90 },
    { race: "fuzzball", id: 142 },
    { race: "fuzzball", id: 78 },
    { race: "fuzzball", id: 8 },
    { race: "fuzzball", id: 130 },
    { race: "fuzzball", id: 160 },
    { race: "fuzzball", id: 111 },
    { race: "fuzzball", id: 143 },
    { race: "fuzzball", id: 49 },
    { race: "fuzzball", id: 91 },
    { race: "fuzzball", id: 16 },
    { race: "fuzzball", id: 137 },
    { race: "fuzzball", id: 127 },
    { race: "fuzzball", id: 87 },
    { race: "fuzzball", id: 163 },
    { race: "fuzzball", id: 3 },
    { race: "fuzzball", id: 34 },
    { race: "fuzzball", id: 71 },
    { race: "fuzzball", id: 61 },
    { race: "fuzzball", id: 69 },
    { race: "fuzzball", id: 54 },
    { race: "fuzzball", id: 59 },
    { race: "fuzzball", id: 14 },
    { race: "fuzzball", id: 35 },
    { race: "fuzzball", id: 43 },
    { race: "fuzzball", id: 58 },
    { race: "fuzzball", id: 96 },
    { race: "fuzzball", id: 147 },
    { race: "fuzzball", id: 95 },
    { race: "fuzzball", id: 116 },
    { race: "fuzzball", id: 79 },
    { race: "fuzzball", id: 75 },
    { race: "fuzzball", id: 107 },
    { race: "fuzzball", id: 179 },
    { race: "fuzzball", id: 82 },
    { race: "fuzzball", id: 165 },
    { race: "fuzzball", id: 140 },
    { race: "fuzzball", id: 164 },
    { race: "fuzzball", id: 101 },
    { race: "fuzzball", id: 83 },
    { race: "fuzzball", id: 166 },
    { race: "fuzzball", id: 104 },
    { race: "fuzzball", id: 175 },
    { race: "fuzzball", id: 136 },
    { race: "fuzzball", id: 120 },
    { race: "fuzzball", id: 100 },
    { race: "fuzzball", id: 66 },
    { race: "fuzzball", id: 18 },
    { race: "fuzzball", id: 53 },
    { race: "fuzzball", id: 72 },
    { race: "fuzzball", id: 102 },
    { race: "fuzzball", id: 125 },
    { race: "fuzzball", id: 67 },
    { race: "fuzzball", id: 98 },
    { race: "fuzzball", id: 73 },
    { race: "fuzzball", id: 10 },
    { race: "fuzzball", id: 128 },
    { race: "fuzzball", id: 134 },
    { race: "fuzzball", id: 126 },
    { race: "fuzzball", id: 168 },
    { race: "fuzzball", id: 76 },
    { race: "fuzzball", id: 117 },
    { race: "fuzzball", id: 13 },
    { race: "fuzzball", id: 48 },
    { race: "fuzzball", id: 155 },
    { race: "fuzzball", id: 105 },
    { race: "fuzzball", id: 106 },
    { race: "fuzzball", id: 114 },
    { race: "fuzzball", id: 174 },
    { race: "fuzzball", id: 2, ai: true }, // not really designated but sure lets reserve one for ai
    { race: "fuzzball", id: 42 },
    { race: "fuzzball", id: 151 },
    { race: "wisdom", id: 27 },
    { race: "wisdom", id: 36 },
    { race: "wisdom", id: 8 },
    { race: "wisdom", id: 67 },
    { race: "wisdom", id: 54 },
    { race: "wisdom", id: 86 },
    { race: "wisdom", id: 30 },
    { race: "wisdom", id: 51 },
    { race: "wisdom", id: 35 },
    { race: "wisdom", id: 64 },
    { race: "wisdom", id: 28 },
    { race: "wisdom", id: 87 },
    { race: "wisdom", id: 53 },
    { race: "wisdom", id: 46 },
    { race: "wisdom", id: 78 },
    { race: "wisdom", id: 56 },
    { race: "wisdom", id: 19 },
    { race: "wisdom", id: 73 },
    { race: "wisdom", id: 97 },
    { race: "wisdom", id: 71 },
    { race: "wisdom", id: 93 },
    { race: "wisdom", id: 33 },
    { race: "wisdom", id: 80 },
    { race: "wisdom", id: 34 },
    { race: "wisdom", id: 66 },
    { race: "wisdom", id: 7 },
    { race: "wisdom", id: 11 },
    { race: "wisdom", id: 52 },
    { race: "wisdom", id: 60 },
    { race: "wisdom", id: 17 },
    { race: "wisdom", id: 81 },
    { race: "wisdom", id: 37 },
    { race: "wisdom", id: 49 },
    { race: "wisdom", id: 40 },
    { race: "wisdom", id: 25 },
    { race: "wisdom", id: 39 },
    { race: "wisdom", id: 61 },
    { race: "wisdom", id: 18 },
    { race: "wisdom", id: 58 },
    { race: "wisdom", id: 32 },
    { race: "wisdom", id: 69 },
    { race: "wisdom", id: 70 },
    { race: "wisdom", id: 22 },
    { race: "wisdom", id: 91 },
    { race: "wisdom", id: 23 },
    { race: "wisdom", id: 85 },
    { race: "wisdom", id: 65 },
    { race: "wisdom", id: 77 },
    { race: "wisdom", id: 84 },
    { race: "wisdom", id: 43 },
    { race: "wisdom", id: 59 },
    { race: "wisdom", id: 90 },
    { race: "wisdom", id: 89 },
    { race: "wisdom", id: 4 },
    { race: "wisdom", id: 48 },
    { race: "wisdom", id: 20 },
    { race: "wisdom", id: 6 },
    { race: "wisdom", id: 24 },
    { race: "wisdom", id: 21 },
    { race: "wisdom", id: 2, ai: true },
    { race: "wisdom", id: 45 },
    { race: "wisdom", id: 55 },
    { race: "wisdom", id: 92 },
    { race: "wisdom", id: 15 },
    { race: "wisdom", id: 47 },
    { race: "wisdom", id: 9 },
    { race: "wisdom", id: 10 },
    { race: "wisdom", id: 79 },
    { race: "wisdom", id: 44 },
    { race: "wisdom", id: 72 },
    { race: "wisdom", id: 31 },
    { race: "wisdom", id: 16 },
    { race: "wisdom", id: 98 },
    { race: "wisdom", id: 95 },
    { race: "wisdom", id: 5 },
    { race: "wisdom", id: 63 },
    { race: "wisdom", id: 94 },
    { race: "wisdom", id: 62 },
    { race: "wisdom", id: 29 },
    { race: "wisdom", id: 50 },
    { race: "wisdom", id: 42 },
    { race: "wisdom", id: 88 },
    { race: "wisdom", id: 75 },
    { race: "wisdom", id: 74 },
    { race: "wisdom", id: 82 },
    { race: "wisdom", id: 13 },
    { race: "wisdom", id: 57 },
    { race: "wisdom", id: 3 },
    { race: "wisdom", id: 26 },
    { race: "wisdom", id: 14 },
    { race: "wisdom", id: 76 },
    { race: "wisdom", id: 83 },
    { race: "wisdom", id: 41 },
    { race: "wisdom", id: 38 },
    { race: "wisdom", id: 68 },
    { race: "wisdom", id: 96 },
    { race: "wisdom", id: 12 },
    { race: "robot", id: 114 },
    { race: "robot", id: 115 },
    { race: "robot", id: 116 },
    { race: "robot", id: 117 },
    { race: "robot", id: 118 },
    { race: "robot", id: 119 },
    { race: "robot", id: 120 },
    { race: "robot", id: 121 },
    { race: "robot", id: 122 },
    { race: "robot", id: 123 },
    { race: "robot", id: 124 },
    { race: "robot", id: 125 },
    { race: "robot", id: 126 },
    { race: "robot", id: 127 },
    { race: "robot", id: 128 },
    { race: "robot", id: 129, ai: true },
    { race: "robot", id: 130 },
    { race: "robot", id: 131 },
    { race: "robot", id: 132 },
    { race: "robot", id: 133 },
    { race: "robot", id: 134 },
    { race: "robot", id: 135 },
    { race: "robot", id: 136 },
    { race: "robot", id: 137, ai: true },
    { race: "robot", id: 138 },
    { race: "robot", id: 139 },
    { race: "robot", id: 140 },
    { race: "robot", id: 141 },
    { race: "robot", id: 142 },
    { race: "robot", id: 143 },
    { race: "robot", id: 144 },
    { race: "robot", id: 145 },
    { race: "robot", id: 146 },
    { race: "robot", id: 147 },
    { race: "robot", id: 148 },
    { race: "robot", id: 149 },
    { race: "robot", id: 151 },
    { race: "robot", id: 152 },
    { race: "robot", id: 153 },
    { race: "robot", id: 154 },
    { race: "robot", id: 155 },
    { race: "robot", id: 156 },
    { race: "robot", id: 158 },
    { race: "robot", id: 159 },
    { race: "robot", id: 160 },
    { race: "robot", id: 161 },
    { race: "robot", id: 162 },
    { race: "robot", id: 163 },
    { race: "robot", id: 165 },
    { race: "robot", id: 166 },
    { race: "robot", id: 167 },
    { race: "robot", id: 168 },
    { race: "robot", id: 169 },
    { race: "robot", id: 170 },
    { race: "robot", id: 171 },
    { race: "robot", id: 172 },
    { race: "robot", id: 173 },
    { race: "robot", id: 174 },
    { race: "robot", id: 175 },
    { race: "robot", id: 176 },
    { race: "robot", id: 177 },
    { race: "robot", id: 178 },
    { race: "robot", id: 179 },
    { race: "robot", id: 180 },
    { race: "robot", id: 181 },
    { race: "robot", id: 182 },
    { race: "robot", id: 183 },
    { race: "robot", id: 184 },
    { race: "robot", id: 185 },
    { race: "robot", id: 186 },
    { race: "robot", id: 187 },
    { race: "robot", id: 188 },
    { race: "robot", id: 189 },
    { race: "robot", id: 190 },
    { race: "robot", id: 191 },
    { race: "robot", id: 192 },
    { race: "robot", id: 193 },
    { race: "robot", id: 194 },
    { race: "robot", id: 195 },
    { race: "robot", id: 196 },
    { race: "robot", id: 197 },
    { race: "robot", id: 198 },
    { race: "robot", id: 200 },
    { race: "robot", id: 201 },
    { race: "robot", id: 206 },
    { race: "robot", id: 207 },
    { race: "robot", id: 214 },
    { race: "robot", id: 215 },
    { race: "robot", id: 220 },
    { race: "robot", id: 221 },
    { race: "robot", id: 228 },
    { race: "robot", id: 234 },
    { race: "robot", id: 242 },
    { race: "robot", id: 248 },
    { race: "robot", id: 256 },
    { race: "robot", id: 257 },
    { race: "robot", id: 258 },
    { race: "robot", id: 259 },
    { race: "robot", id: 260 },
    { race: "robot", id: 261 },
    { race: "robot", id: 262 },
    { race: "robot", id: 263 },
    { race: "robot", id: 264 },
    { race: "robot", id: 265 },
    { race: "robot", id: 266 },
    { race: "robot", id: 267 },
    { race: "robot", id: 268 },
    { race: "robot", id: 269 },
    { race: "robot", id: 270 },
    { race: "robot", id: 271 },
    { race: "robot", id: 272 },
    { race: "robot", id: 274 },
];

for (const avatar of avatar_list) {
    avatar.shuffle_hash = (avatar.id * 1299709) % 1987;
}

function sortById(a: Entry, b: Entry): number {
    return a.id - b.id;
}

function sortByShuffleHash(a: Entry, b: Entry): number {
    return a.shuffle_hash - b.shuffle_hash;
}

export const avatars: { [race: string]: Array<number> } = {
    aquatic: avatar_list
        .filter((x) => x.race === "aquatic" && !x.ai)
        .sort(sortByShuffleHash)
        .map((a) => a.id),

    bird: avatar_list
        .filter((x) => x.race === "bird" && !x.ai)
        .sort(sortByShuffleHash)
        .map((a) => a.id),

    fuzzball: avatar_list
        .filter((x) => x.race === "fuzzball" && !x.ai)
        .sort(sortByShuffleHash)
        .map((a) => a.id),

    wisdom: avatar_list
        .filter((x) => x.race === "wisdom" && !x.ai)
        .sort(sortByShuffleHash)
        .map((a) => a.id),

    robot: avatar_list
        .filter((x) => x.race === "robot" && !x.ai)
        .sort(sortByShuffleHash)
        .map((a) => a.id),
};

export function avatar_background_class(race: Race): string {
    switch (race) {
        case "aquatic":
            return "bg-blue";
        case "fuzzball":
            return "bg-yellow";
        case "bird":
            return "bg-green";
        case "wisdom":
            return "bg-magenta";
        case "robot":
            return "bg-red";
    }
}
